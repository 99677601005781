.message-layout {
  height: 100%;
  width: 100%;
  display: table;

  > div {
    text-align: center;
    vertical-align: middle;
    display: table-cell;
  }
}

.hl-character {
  color: #ed7831;
}
