.word-selection-table {
  cdk-virtual-scroll-viewport.cdk-virtual-scroll-viewport {
    height: calc(100vh - 200px) !important;

    table[nz-table-content=""] {
      tr[nz-table-measure-row=""] {
        height: 1px !important;
      }
    }
  }
}
.word-selection-table.empty {
  cdk-virtual-scroll-viewport.cdk-virtual-scroll-viewport {
    table[nz-table-content=""] {
      height: calc(100vh - 205px) !important;
    }
  }
}
