.lng-content-container {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.01rem;
  text-rendering: optimizeLegibility;
  padding: 15px;
  color: #212529;
  font-size: 16px;

  * {
    color: rgba(0, 0, 0, 0.8);
    letter-spacing: 0.01rem;
    line-height: 1.5;
  }

  [data-lng-former-link=""] {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }

  ul, ol {
    padding-left: 25px;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border: 1px solid #ddd;
    margin-bottom: 15px;
  }

  tr {
    border-bottom: 1px solid #ddd;
  }

  td {
    width: 100%;
    padding: 5px;
  }

  td:not(:last-child) {
    border-right: 1px solid #ddd;
  }

  .single-image {
    margin-bottom: 15px;
  }

  .single-image > [lng-former-link=""] > img {
    margin-bottom: 5px;
  }

  img {
    margin-bottom: 5px;
    max-width: 100%;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 10px;
    margin-top: 15px;
  }

  p {
    margin-bottom: 25px !important;
  }
}

.word-occurrence {
  font-weight: bold;
  color: #2ab97b;
}
