.document {
  height: 200px;
}



.document-list {
  padding: 25px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  justify-content: stretch;
  grid-gap: 25px;

  &.ebook-list {
    grid-template-columns: repeat(auto-fill, minmax(103px, 1fr));

    .ant-card-head {
      padding: 5px;
      padding-top: 0px;
      font-size: 13px;
      height: 30px;
      min-height: 30px;

      .ant-card-head-title {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    .document {
      height: 157px;
    }
  }

  //.document {
  //  cursor: pointer;
  //  display: block;
  //  width: calc(100% - 35px);
  //  min-height: 300px;
  //  //width: 100%;
  //  //min-width: 320px;
  //  //position: relative;
  //  mat-card-actions {
  //    position: absolute;
  //    bottom: 15px;
  //    right: 15px;
  //    visibility: hidden;
  //  }
  //  &:hover mat-card-actions {
  //    visibility: visible;
  //  }
  //
  //  mat-chip {
  //    padding: 0 7px;
  //    font-size: 12px;
  //    min-height: 22px;
  //  }
  //}

  .category {
    width: 100%;
    display: table;
    background-color: #b2eeab;

    .category-name {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
      font-size: 40px;
      user-select: none;

      mat-icon {
        //transform: scale(3);
        font-size: 72px;
        width: 72px;
        height: 72px;
      }
    }
  }
}

.loader-global {
  position: fixed;
  top: calc(50% - 22.5px);
  left: calc(50% - 22.5px);
}

.document-category {
  height: 290px;
}

.category-up {
  font-size: 45px;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  padding-top: 120px;
}

.category {
  background-color: #b2eeab;

  .document-category {
    display: table;
    width: 100%;

    .category-name {
      font-size: 26px;
      display: table-cell;
      vertical-align: middle;
      text-align: center;
      width: 100%;
    }
  }
}
