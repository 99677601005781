/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "scss/editor-content.scss";
@import "scss/document-list";
@import "scss/document";
@import "scss/kindle";

/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "KAISHO";
  src: url("assets/fonts/KAISHO.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/KAISHO.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("assets/fonts/KAISHO.otf") format("opentype"), /* Open Type Font */
  url("assets/fonts/KAISHO.svg") format("svg"), /* Legacy iOS */
  url("assets/fonts/KAISHO.ttf") format("truetype"), /* Safari, Android, iOS */
  url("assets/fonts/KAISHO.woff") format("woff"), /* Modern Browsers */
  url("assets/fonts/KAISHO.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: Roboto, monospace;
}

body {
  display: block;
  margin: 0;
}

span.word:hover {
  background-color: rgba(42, 185, 123, 0.3);
  cursor: pointer;
}

span.word.active {
  background-color: rgb(42, 185, 123);
  color: white;
  font-weight: normal;
}

.working-article {
  p {
    font-size: 150%;
  }

  h1 {
    font-size: 225%;
  }
}

.word-whole {
  background-color: rgb(42, 185, 123);
  padding: 1px 3px;
  position: relative;
}

.word-whole.highlight {
  //background-color: rgb(185, 151, 57);
  span.active {
    color: #fff29d;
  }
}

.word-whole + .word-whole {
  margin-left: 5px;
}

.word-whole .word-tooltip {
  display: none;
}

[data-md-tooltip] {
  position: relative;
}

[data-md-tooltip]:before {
  content: attr(data-md-tooltip);
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  padding: 8px;
  transform: translateX(-50%) scale(0);
  transition: transform 0.1s ease-in-out;
  transform-origin: top;
  background: #616161e6;
  color: white;
  border-radius: 2px;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  z-index: 25;
}

[data-md-tooltip]:hover:before {
  transform: translateX(-50%) scale(1);
}

.center-loading {
  margin-left: auto;
  margin-right: auto;

  top: calc(50% - 60px);
  position: absolute;
}

.hide {
  visibility: hidden;
}

//.editor {
//  min-height: calc(100% - 20px);
//  background-color: red;
//}

div.frame {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;

  div.img-placeholder {
    width: 100%;
    display: block;
    padding-bottom: 56%;
    background-color: #ededed;
    position: relative;
    margin: 0 0 -5px;

    img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%
    }
  }

  p {
    margin-bottom: 50px;
    margin-top: 0;
    padding: 5px;
    text-align: center;
    background-color: black;
    color: white;
  }
}

// video editor
.play-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: white;

  &:hover {
    color: #c5dec2;
  }

  &:active {
    color: #98de88;
  }
}


.notification-container {
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  margin: 8px;
  max-width: 33vw;
  min-width: 344px;
  padding: 14px 16px;
  min-height: 48px;
  transform-origin: center;
  position: relative;
  color: rgba(255, 255, 255, .7);
  background: #323232;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, .2), 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12);

  &.info {
    > .notification-action > button {
      color: #ff4081;
    }
  }

  &.danger {
    color: #fda09b;

    > .notification-action > button {
      color: #f73627;
    }
  }

  &.warning {
    color: #b7b978;

    > .notification-action > button {
      color: #edc828;
    }
  }

  &.success {
    color: #96b98a;

    > .notification-action > button {
      color: #88ec6a;
    }
  }

  .notification-action {
    position: absolute;
    float: right;
    right: 0;
    bottom: 5px;
  }
}

.error-list {
  padding: 5px;
  border-left: solid 3px #f44336;

  > li {
    color: #f44336;
    list-style: none;
  }
}

.hl {
  color: #f48636;
}

// occurrences for character

.occurrences-info, .compounds-info {
  h1, h2, h3, h4, h5, h6 {
    font-size: 18px;
  }
}

.working-article > main > div {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  > p {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  code {
    font-family: "Courier", monospace;

    > .word {
      font-family: "Courier", monospace;
    }
  }
}

// ng-zorro
.full-height {
  height: 100vh;
}

.word-list-table table[nz-table-content=""] {
  //tr:not(.table-padding) {
  //  height: 30px;
  //}
}

.ngx-file-drop__content, .ngx-file-drop__drop-zone {
  border: none !important;
  height: calc(100% - 10px) !important;
  background: none !important;
  box-shadow: none !important;
}

.ngx-file-drop__drop-zone--over .ant-upload-drag{
  border-color: #007bff !important;
}

